module.exports = [{
      plugin: require('../packages/gatsby-plugin-featureflags/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://27523ec79806425c9ccfbe6f4df041c9@o171610.ingest.sentry.io/5708433","sampleRate":0.1,"enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"lang":"*","repositoryName":"lmplus"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Les Mills On Demand","short_name":"Les Mills On Demand","start_url":"/?utm_source=pwa_install","background_color":"#222","theme_color":"#222","display":"minimal-ui","icon":"/home/gitlab-runner/builds/xkV_szLyC/0/lesmills-international/plus/frontend/web-app/packages/gatsby-theme-acquisition/src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d6a1d8e002c1c9aad0793a26dd4b6b4"},
    },{
      plugin: require('../packages/gatsby-theme-acquisition/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://27523ec79806425c9ccfbe6f4df041c9@o171610.ingest.sentry.io/5708433","sampleRate":0.1},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Les Mills On Demand","short_name":"Les Mills On Demand","start_url":"/?utm_source=pwa_install","background_color":"#222","theme_color":"#222","display":"minimal-ui","icon":"/home/gitlab-runner/builds/xkV_szLyC/0/lesmills-international/plus/frontend/web-app/packages/gatsby-theme-engagement/src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d14c02f135e6304a8888082e45c5054"},
    },{
      plugin: require('../packages/gatsby-theme-engagement/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"pure":true,"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"82511769-c747-4c84-8099-c481a7da6a09","clientToken":"pubba10ac84e7dc903bf91f809a845cd7ca","site":"datadoghq.com","service":"Les Mills Plus Web","sampleRate":100,"sessionReplaySampleRate":0,"enabled":true,"env":"prod","version":"v1.778.0","trackInteractions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PXDW4Q2","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"62e33f4678506b119095260e","options":{"bootstrap":"localstorage"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
